import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { getFromLocale } from "@/utils";
export const getClientSideCookie = (name) => {
  if (typeof document !== "undefined") {
    const cookieValue = document?.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`))
      ?.split('=')[1];

    return cookieValue;
  }

};

export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.API_URL}/api`,
  prepareHeaders: async (headers) => {
    const lang = getClientSideCookie('lang')
    const currency = getClientSideCookie('currency')
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    headers.set("Authorization", `Bearer ${getFromLocale()}`);
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Language", lang);
    headers.set("Currency", currency);

    return headers;
  },
});

